import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { ContentPage, EditContentPayload } from 'gtr-types'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrYesNoInput from '@/modules/common/components/ui-core/gtr-yesno-input/gtr-yesno-input.vue'

@Component({
  name: 'GtrRegistrationModuleEditContentPageView',
  computed: {
    ...mapState('contentpages', ['currentDevUUID', 'contentpages', 'changelog'])
  },
  components: {
    changelog: ChangeLog,
    'on-navbar-input': GtrYesNoInput
  }
})
export default class GtrRegistrationModuleEditContentPageView extends Vue {
  // VUEX BINDINGS
  currentDevUUID!: Record<string, any>;

  // COMPONENT STATE VARs
  event_uuid = this.$route.params.event_uuid;
  content_uuid = this.$route.params.content_page_uuid;
  contentName = '';
  changelog!: any;
  submitting = false;
  loading = false;
  contentpages!: Record<string, any>;
  pageVersion = 'default';
  contentPage: ContentPage = {
    languageToUse: 'en',
    name: null,
    navbar_name: null,
    show_in_navbar: null,
    display_order: null,
    body: {}
  }

  // LIFECYCLE METHODS.
  /**
   * retrieves content pages, event content, and changelog data.
   */
  async created () {
    try {
      let pages: Record<string, any>
      if (!this.contentpages) {
        pages = await this.$store.dispatch('contentpages/getContentPages', {
          event_uuid: this.event_uuid,
          content_uuid: this.content_uuid
        })
      }
      pages = this.contentpages
      const [name] = Object.keys(pages).filter(key => pages[key].uuid === this.content_uuid)
      this.contentName = name
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  async mounted () {
    try {
      await this.$store.dispatch('contentpages/getCurrentDevUUID', {
        type: 'page',
        sub_type: this.contentName,
        event_uuid: this.event_uuid
      })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  // WATCHER METHODS

  // When a user changes the pageVersion. Fetch the page data by uuid.
  @Watch('pageVersion')
  async onPageVersionChange (revision_uuid: string) {
    try {
      if (revision_uuid !== 'default') {
        // fetch the data for a given revisions, update the store.
        await this.$store.dispatch('contentpages/getContentpageByRevisionUuid', {
          event_uuid: this.event_uuid,
          revision_uuid
        })
      }
    } catch (error) {
      Container.get(Notification).error('there was a problem fetching the content page data.')
    }
  }

  /**
   * watch the currentDevUUID for changes.
   * use the dev version to hydrate the component state.
   * @param {any} payload - the changed data.
   */
  @Watch('currentDevUUID')
  async onCurrentDevUUIDChange (payload: any) {
    if (payload && payload.dev) {
      const { dev } = payload
      this.contentPage.name = dev.page_data.name
      this.contentPage.navbar_name = dev.page_data.navbar_name
      this.contentPage.body = dev.page_data.body
      this.contentPage.display_order = dev.page_data.display_order
      this.contentPage.show_in_navbar = dev.page_data.show_in_navbar
    }
  }

  get dev_uuid () {
    return this.currentDevUUID.dev?.uuid ?? null
  }

  get live_uuid () {
    return this.currentDevUUID.live?.uuid ?? null
  }

  // COMPONENT UTILITY METHODS
  async handleDeployed () {
    await this.$store.dispatch('contentpages/getCurrentlyDeployedLiveUUID', {
      type: 'page',
      sub_type: this.contentName,
      event_uuid: this.event_uuid
    })
  }

  // submits content page data to the backend.
  async submit () {
    const form = (this.$refs.editwContentPageForm as HTMLFormElement)
    const observerForm = (this.$refs.observerForm as any)
    const isValid = await observerForm.validate()
    if (isValid && form.validate()) {
      try {
        this.submitting = true
        const payload: EditContentPayload = {
          event_uuid: this.event_uuid,
          data: {
            ...this.contentPage,
            name: this.contentPage.name,
            body: this.contentPage.body,
            navbar_name: this.contentPage.navbar_name,
            show_in_navbar: this.contentPage.show_in_navbar,
            display_order: this.contentPage.display_order
          },
          name: this.contentName
        }
        await this.$store.dispatch('contentpages/editContentPage', payload)
        Container.get(Notification).success('Content page successfully edited.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        // finally after updating/ inserting new content page. update the uuid.
        this.content_uuid = this.contentpages[this.contentName].uuid
        this.submitting = false
      }
    }
  }

  prefillNavbarName (val: string) {
    if (!val) return
    this.contentPage.navbar_name = val.replace(/[^\w]/gi, '_')
  }

  // this is an event handler that will assign the pageVersion.
  updatePageVersion (uuid: string) {
    this.pageVersion = uuid
  }
}
